@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply mb-2 font-medium;
    font-size: 3.6rem;
    font-family: "Freestyle Script", sans-serif;
  }
  h2 {
    @apply mb-2 font-medium;
    font-size: 2.488rem;
  }
  h3 {
    @apply mb-2 font-medium;
    font-size: 2.074rem;
  }
  h4 {
    @apply mb-2 font-medium;
    font-size: 1.728rem;
  }
  h5 {
    @apply mb-2 font-medium;
    font-size: 1.44rem;
  }
  h6 {
    @apply mb-2 font-medium;
    font-size: 1.2rem;
  }
  p {
    line-height: 1.6;
  }
}

html {
  font-size: 18px;
}

html:not([data-scroll="0"]) header {
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);
}

#section-top {
  background-size: cover;
  background-repeat: no-repeat;
}

.hamburger {
  padding-top: 0;
  padding-bottom: 0;
}
